import { useEffect, useState, useRef } from 'react';
import Head from 'next/head';

import { Container } from 'layouts';
import { Box, BoxFooter, Select, Input, QueryTabs } from 'components';
import { OrdersTable } from 'modules/orders/components/OrdersListTable';
import { Obscure } from 'components';
import { baseSiteTitle } from 'pages/_app';
import { parseDateRange, formatAsCurrency } from 'helpers/reporting-helpers';
import { useGetOrdersQuery, useGetUsersQuery, useGetStaffSalesStatsQuery, OrderStatus, SortOrder, UserRole } from '@graphql';
import TotalSalesSvg from 'assets/svg/icon-dashboard-sales-stat.svg';
import { Button } from 'modules/common';
import { useActiveSession } from 'hooks/useActiveSessionContext';
import { CreateNewOrderButton } from 'components/CreateNewOrderButton';
import { useRouter } from 'next/router';
import { getDefaultQueryParams } from 'pages/orders';

type FilterType = {
  from?: string;
  to?: string;
};

type StatProps = {
  heading: string;
  value: any;
  visible?: boolean;
  hoverEffect?: boolean;
};

const Stat = ({ heading, value, visible, hoverEffect }: StatProps) => {
  const containerRef = useRef(null);
  const [hasOverflow, setHasOverflow] = useState(false);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      setHasOverflow(container.scrollWidth > container.clientWidth);
    }
  }, [value, visible]);

  const dynamicClassName = `
    mt-1
    text-3xl
    font-semibold
    text-gray-900
    truncate
    overflow-hidden
    ${hoverEffect && hasOverflow ? 'text-2xl' : 'text-3xl'}
    ${hoverEffect && hasOverflow ? 'overflow-visible hover:text-sm' : ''}
  `;

  return (
    <div className="flex flex-col">
      <div className="text-sm font-medium text-gray-500 truncate">{heading}</div>
      <div ref={containerRef} className={dynamicClassName}>
        <Obscure value={value} visible={visible ?? true} />
      </div>
    </div>
  );
};

const orderStatusTabs = [
  { name: 'Latest', id: 'LATEST' },
  { name: 'Ready to process', id: OrderStatus.ReadyToProcess },
  { name: 'Processing', id: OrderStatus.Processing },
  { name: 'Complete', id: OrderStatus.Complete },
];

const NUMBER_OF_LATEST_ORDERS = 5;

type SelectedUser = { id: string; firstName: string; lastName: string; role?: string };

const DashboardPage = () => {
  const { activeSession, defaultShowroom, tailor } = useActiveSession();

  const [filters, setFilters] = useState<FilterType>({ from: null, to: null });

  const [areDetailsVisible, setAreDetailsVisible] = useState<boolean>(false);
  const [staff, setStaff] = useState<SelectedUser>(activeSession?.user);
  const { query } = useRouter();

  useEffect(() => {
    setStaff(activeSession?.user);
  }, [activeSession]);

  // staff cannot see admin data
  const { data: usersData, loading: isLoadingUsers } = useGetUsersQuery({
    variables: { ...(staff?.role === UserRole.Staff ? { role: UserRole.Staff } : {}) },
    fetchPolicy: 'cache-first',
  });

  const { data: salesData, loading: isLoadingDetails } = useGetStaffSalesStatsQuery({
    variables: {
      staffId: staff?.id,
      ...parseDateRange(filters),
    },
    skip: !staff,
  });

  const { data, loading: isLoadingOrders } = useGetOrdersQuery({
    variables: {
      salesRepId: staff?.id,
      take: NUMBER_OF_LATEST_ORDERS,
      createdAt: parseDateRange(filters),
      sortField: 'createdAt',
      sortOrder: SortOrder.Desc,
      orderStatus: query?.orderStatus === 'LATEST' ? undefined : (query.orderStatus as OrderStatus),
    },
    skip: !staff,
  });

  const handleSalesRepOnChange = (event) => {
    event.preventDefault();
    const { value } = event.target;

    setStaff(usersData?.users.find((u) => u.id === value));
  };

  const handleOnShowHideDetails = (event) => {
    event.preventDefault();
    setAreDetailsVisible(!areDetailsVisible);
  };

  return (
    <>
      <Head>
        <title>{baseSiteTitle} | Dashboard</title>
      </Head>
      <Container>
        <Box isLoading={isLoadingUsers}>
          <div className="flex justify-between items-center">
            <div className="flex gap-8">
              <Select
                label=""
                htmlProps={{
                  onChange: handleSalesRepOnChange,
                  className: 'outline-0 border-0',
                  defaultValue: staff?.id,
                }}
              >
                {usersData?.users?.length ? (
                  [...usersData.users]
                    ?.sort((a, b) => a.lastName?.localeCompare(b?.lastName))
                    .map((user) => (
                      <option key={user.id} value={user.id}>
                        {`${user.firstName} ${user.lastName}`}
                      </option>
                    ))
                ) : (
                  <option value={staff?.id}>{`${staff?.firstName} ${staff?.lastName}`}</option>
                )}
              </Select>
              <div className="flex gap-4">
                <div>
                  <Input
                    label="Date (From)"
                    htmlProps={{ type: 'date', onChange: ({ currentTarget }) => setFilters({ ...filters, from: currentTarget.value }) }}
                  />
                </div>

                <div>
                  <Input
                    label="Date (To)"
                    htmlProps={{ type: 'date', onChange: ({ currentTarget }) => setFilters({ ...filters, to: currentTarget.value }) }}
                  />
                </div>
              </div>
            </div>

            <CreateNewOrderButton />
          </div>
        </Box>
        <div className="flex flex-col lg:flex-row">
          <div className="w-full lg:w-2/3 py-3">
            <Box isLoading={isLoadingDetails} hasPadding={false}>
              <div className="flex gap-4 items-start p-6">
                <div>
                  <TotalSalesSvg className="fill-current" width={60} height={60} />
                </div>

                <div className="flex flex-col gap-8">
                  <Stat
                    heading="Total Sales"
                    value={
                      salesData?.stats?.salesAmount ? formatAsCurrency(Number(salesData?.stats?.salesAmount), tailor?.locale, tailor?.currency) : '-'
                    }
                    visible={areDetailsVisible}
                  />
                </div>
              </div>
              <BoxFooter>
                <div className="flex justify-end">
                  <span className="text-sm text-blue-500 underline cursor-pointer" onClick={handleOnShowHideDetails}>
                    {!areDetailsVisible ? 'Show' : 'Hide'} sales
                  </span>
                </div>
              </BoxFooter>
            </Box>
          </div>
          <div className="w-full lg:w-1/3 py-3 lg:pl-3">
            <div className="grid grid-cols-2 gap-3">
              <Box>
                <Stat heading="Orders" value={salesData?.stats?.ordersTotal ?? '-'} />
              </Box>
              <Box>
                <Stat heading="Alterations" value={salesData?.stats?.alterationsTotal ?? '-'} />
              </Box>
              <Box>
                <Stat
                  heading="ATV"
                  hoverEffect
                  value={salesData?.stats?.ATV ? formatAsCurrency(Number(salesData?.stats?.ATV), tailor?.locale, tailor?.currency, true, true) : '-'}
                />
              </Box>
              <Box>
                <Stat heading="IPT" value={salesData?.stats?.IPT ?? '-'} />
              </Box>
            </div>
          </div>
        </div>
        <div className="mt-3">
          <Box hasPadding={false}>
            <QueryTabs queryParam="orderStatus" tabs={orderStatusTabs} />
            <div className="p-4">
              <div className="font-semibold p-4">{orderStatusTabs.find((t) => t.id === query.orderStatus)?.name || 'Latest'} orders</div>
              <Box isLoading={isLoadingOrders} hasPadding={false}>
                {!data?.orders?.items?.length && (
                  <div className="text-center p-20">
                    <div className="mt-3 text-sm text-gray-900">No orders</div>
                    <p className="mt-1 text-sm text-gray-500">There are no orders yet.</p>
                  </div>
                )}
                {!!data?.orders?.items?.length && (
                  <div className="p-6">
                    <OrdersTable isLoading={isLoadingOrders} orders={data?.orders?.items} paginate={false} />
                    <div className="pt-4 flex justify-end space-x-2">
                      <Button isLink={true} href="/orders" query={getDefaultQueryParams({ showroom: defaultShowroom?.id })}>
                        View all orders
                      </Button>
                    </div>
                  </div>
                )}
              </Box>
            </div>
          </Box>
        </div>
      </Container>
    </>
  );
};

export default DashboardPage;
